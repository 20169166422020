import $ from 'jquery';
import 'slick-carousel';

jQuery(function() {
    //  Grab Cursor when grabbing a Slide
    $(".slick-slide").on("mousedown touchstart", function() {
        $(this).addClass('grabbing');
    });

    $(".slick-slide").on("mouseup touchend", function() {
        $(this).removeClass('grabbing');
    });

    $('.slick-form-reservation').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        dots: true,
        fade: true,
        infinite: true
    });

    $('.slick-vehicule').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: true,
        dots: false,
        fade: false,
        infinite: true
    });
});